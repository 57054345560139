import * as React from "react";
import { TestimonialSliderPType } from "@types";
import TestimonialsSlider from "../../components/Sliders/TestimonialsSlider/TestimonialsSlider";
import CustomerSatisfactionBanner from "../../components/Banners/CustomerSatisfactionBanner/CustomerSatisfactionBanner";

import styles from "./TestimonialSliderPContainer.module.scss";

type TestimonialSliderPContainerProps = {
  props: TestimonialSliderPType;
  hasCustomerSatisfaction?: boolean;
  textFont?: string;
  isPadBottom64?: boolean;
  isPartner?: boolean;
};

const TestimonialSliderPContainer: React.FC<TestimonialSliderPContainerProps> =
  ({
    props: { testimonialModuleRelationship: testimonialArray, bWhite },
    hasCustomerSatisfaction = false,
    textFont = "",
    isPadBottom64,
    isPartner,
  }) => (
    <section
      data-testid="section:sliderContainer"
      className={`pad-top--large
        ${textFont}
        ${styles.sliderContainer}
        ${bWhite ? styles.bWhite : ""}
        ${isPadBottom64 && styles.isPadBottom64}
        ${isPartner && styles.paddingTopMedium}
        `}
    >
      <TestimonialsSlider sliderArray={testimonialArray} />
      {hasCustomerSatisfaction && <CustomerSatisfactionBanner />}
    </section>
  );

export default TestimonialSliderPContainer;
