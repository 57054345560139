export const deIndexSeo = (wpPageObject: Queries.WpPage) => {
  if (wpPageObject.seo?.metaRobotsNoindex === "noindex") return wpPageObject;
  const updatedSEO = {
    ...wpPageObject,
    seo: {
      ...wpPageObject.seo,
      metaRobotsNoindex:
        process.env.GATSBY_ENVIRONMENT === "dev" ? "noindex" : "index",
      metaRobotsNofollow:
        process.env.GATSBY_ENVIRONMENT === "dev" ? "nofollow" : "follow",
    },
  };
  return updatedSEO;
};
