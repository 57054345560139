import * as React from "react";
import { TestimonialItemType } from "@types";
import Slider from "react-slick";

import TestimonialItem from "./TestimonialItem";
import RightArrow from "../../SVGIcons/RightArrow/RightArrow";
import LeftArrow from "../../SVGIcons/LeftArrow/LeftArrow";

import "./TestimonialsSlick.scss";
import styles from "./Testimonials.module.scss";

interface ArrowProps {
  isPrevious?: boolean;
  className?: string;
  onClick?: () => void;
}

const TestimonialsSlider: React.FC<{ sliderArray: TestimonialItemType[] }> = ({
  sliderArray,
}) => {
  const testimonialArray = sliderArray.map((el, index) => (
    <TestimonialItem key={index} data={el} />
  ));

  const Arrow = ({ onClick, className, isPrevious = false }: ArrowProps) => (
    <button className={`${styles.arrow} ${className}`} onClick={onClick}>
      {isPrevious ? <LeftArrow /> : <RightArrow />}
    </button>
  );

  const settings = {
    draggable: true,
    infinite: false,
    arrows: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    nextArrow: <Arrow />,
    prevArrow: <Arrow isPrevious />,
  };

  return (
    <div
      className={`${styles.sliderContainer} testimonial__slider`}
      data-testid="testimonials-slider"
    >
      <Slider {...settings}>{testimonialArray}</Slider>
    </div>
  );
};

export default TestimonialsSlider;
