import { create } from "zustand";

type UsePageSignupPageStoreState = {
  timeStart: number;
  timeEnd: number;
};

type UsePageSignupPageStoreActions = {
  setTimeEnd: (timeEnd: number) => void;
  setTimeStart: (timeStart: number) => void;
  resetPageState: () => void;
};

const initialState: UsePageSignupPageStoreState = {
  timeStart: 0,
  timeEnd: 0,
};

const usePageSignupPageStore = create<
  UsePageSignupPageStoreActions & UsePageSignupPageStoreState
>(set => ({
  ...initialState,
  setTimeEnd: timeEnd => set({ timeEnd }),
  setTimeStart: timeStart => set({ timeStart }),
  resetPageState: () => set(initialState),
}));

export default usePageSignupPageStore;
