import * as React from "react";
import { TestimonialItemType } from "../../../assets/Types/AcfGroupTypes/TestimonialSliderP.type";
import ReviewStars from "../../Reviews/ReviewStars";
import { Size } from "../../../assets/Enums/Size.enum";
import TestimonialVideo from "../../Videos/TestimonialVideo/TestimonialVideo";
import { ImageMediaItemUrl } from "@types";

import styles from "./Testimonials.module.scss";

const TestimonialItem: React.FC<{ data: TestimonialItemType }> = ({
  data: {
    testimonialsC: {
      testimonialsAuthor,
      testimonialsLabel,
      testimonialsLocation,
      testimonialsQuote,
      testimonialVideoBackgroundImage,
      testimonialWistiaId,
    },
  },
}) => {
  return (
    <div className={`${styles.grid} full-width`}>
      <div className={styles.gridContent}>
        <p
          style={{ marginBottom: "16px" }}
          className={"badge--text primary--blue-text"}
        >
          {testimonialsLabel}
        </p>
        <ReviewStars className={styles.reviewStars} size={Size.SMALL} />
        <p className={styles.quote}>&ldquo;{testimonialsQuote}&rdquo;</p>
        <p className={"primary--grey-text"}>
          — {testimonialsAuthor} from {testimonialsLocation}
        </p>
      </div>
      <TestimonialVideo
        videoBackgroundImage={
          testimonialVideoBackgroundImage as unknown as ImageMediaItemUrl
        }
        videoId={testimonialWistiaId}
      />
    </div>
  );
};

export default TestimonialItem;
