import * as React from "react";
import { graphql, PageProps } from "gatsby";
import MainLayout from "../../layouts/main";
import TestimonialSliderPContainer from "../../containers/TestimonialSliderPContainer/TestimonialSliderPContainer";
import { Image, TestimonialSliderPType, monarchAsideContent } from "@types";
import MonarchTrialSignUpFormContainer from "../../containers/MonarchTrialSignUpFormContainer/MonarchTrialSignUpFormContainer";
import { YoastSEOType } from "../../assets/Types";
import SEO from "gatsby-plugin-wpgraphql-seo";
import { mxpSignupFirstView } from "../../utils/mixpanelEvents/mixpanelEvents";
import { useEffect } from "react";
import SignUpHeader from "../../components/Headers/SignUpHeader";
import { deIndexSeo } from "../../utils/deIndexSeo";

type SignUpLandingPageType = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      featuresPageHeroP: monarchAsideContent;
      testimonialSliderP: TestimonialSliderPType;
      signUpFormP: {
        signUpTitle: string;
      };
      trialSignUpHeroP: {
        trialSignUpPageLogo: Image;
      };
    };
  };
};

const SignUpLandingPage: React.FC<PageProps<SignUpLandingPageType>> = ({
  data,
}) => {
  const {
    wpPage,
    wpPage: { template },
  } = data;

  const {
    featuresPageHeroP,
    signUpFormP: { signUpTitle },
    testimonialSliderP,
    trialSignUpHeroP: { trialSignUpPageLogo },
  } = template;

  useEffect(() => {
    mxpSignupFirstView("TESTGROUP_ID");
  }, []);

  return (
    <MainLayout hasNoHeader={true}>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}

      <SignUpHeader logoData={trialSignUpPageLogo} />

      <MonarchTrialSignUpFormContainer
        specialtiesId={0}
        isTrialSignUpPage
        isSimplifyPage
        signUpTitle={signUpTitle}
        asidePageData={featuresPageHeroP}
      />

      <TestimonialSliderPContainer props={testimonialSliderP} />
    </MainLayout>
  );
};

export default SignUpLandingPage;

export const query = graphql`
  query SignUpLandingPageTemplate($id: String) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      template {
        templateName
        ... on WpSignUpLandingPageTemplate {
          templateName
          featuresPageHeroP {
            footerCopy
            featuresPageHeroSubcopy
            featuresPageHeroTitle
            microcopy
            benefitsList {
              benefit
              tooltip
            }
            benefitsListTitle
          }
          testimonialSliderP {
            testimonialHeadline
            testimonialSubcopy
            testimonialModuleRelationship {
              ... on WpTestimonial {
                testimonialsC {
                  testimonialWistiaId
                  testimonialsAuthor
                  testimonialsLabel
                  testimonialsLocation
                  testimonialsQuote
                  testimonialVideoBackgroundImage {
                    altText
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData(
                          width: 800
                          placeholder: BLURRED
                          formats: [WEBP, JPG, AUTO]
                        )
                      }
                    }
                  }
                }
              }
            }
          }
          signUpFormP {
            signUpTitle
          }
          ...FragmentTrialSignUpHero
        }
      }
    }
  }
`;
