import React from "react";
import styles from "./Tooltip.module.scss";

interface TooltipProps {
  content: string | React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  contentClassName?: string;
}
const Tooltip: React.FC<TooltipProps> = ({
  content,
  children,
  className = "",
  contentClassName = "",
}) => {
  const tooltipClasses = `${styles.featureTooltip} ${className}`;
  const contentClasses = `${styles.tooltipCopy} ${contentClassName}`;

  return (
    <span data-testid="div:Tooltip" className={tooltipClasses}>
      {children}
      <span className={contentClasses}>{content}</span>
    </span>
  );
};

export default Tooltip;
