import React from "react";

const Info: React.FC<{ className?: string; dark?: boolean }> = ({
  className = "",
  dark = false,
}) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={dark ? "#BBBBBB" : "none"}
      xmlns="http://www.w3.org/2000/svg"
      data-testid="info-icon"
    >
      <circle
        cx="8"
        cy="8"
        r="7.5"
        stroke={dark ? "#BBBBBB" : "#747474"}
      ></circle>
      <path
        d="M8.00586 4.52344C8.48047 4.52344 8.87305 4.14258 8.87305 3.66211C8.87305 3.1875 8.48047 2.80078 8.00586 2.80078C7.52539 2.80078 7.13281 3.1875 7.13281 3.66211C7.13281 4.14258 7.52539 4.52344 8.00586 4.52344ZM7.27344 12H8.73242V5.5957H7.27344V12Z"
        fill={dark ? "white" : "#747474"}
      ></path>
    </svg>
  );
};

export default Info;
