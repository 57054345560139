import * as React from "react";
import CustomerSatisfactionBanner from "../../components/Banners/CustomerSatisfactionBanner/CustomerSatisfactionBanner";
import BlackCheckmark from "../../components/SVGIcons/BlackCheckmark/BlackCheckmark";
import SignUpForm from "../../components/SignUpForm/SignUpForm";
import { monarchAsideContent } from "@types";
import HorizontalRule from "../../components/UI/HorizontalRule";
import Tooltip from "../../components/Tooltip/Tooltip";
import InfoIcon from "../../components/SVGIcons/Info/Info";

import styles from "./MonarchTrialSignUpFormContainer.module.scss";

type MonarchTrialSignUpFormContainerProps = {
  isTrialSignUpPage?: boolean;
  asidePageData: monarchAsideContent;
  specialtiesId?: number;
};

const MonarchTrialSignUpFormContainer: React.FC<MonarchTrialSignUpFormContainerProps> =
  ({ isTrialSignUpPage = false, asidePageData, specialtiesId = 1 }) => {
    const {
      footerCopy,
      featuresPageHeroSubcopy,
      featuresPageHeroTitle,
      benefitsListTitle,
      benefitsList,
    } = asidePageData;

    const textFont = isTrialSignUpPage ? "" : "monarch-font";
    const hasPadding = index => {
      return isTrialSignUpPage && benefitsList.length === index + 1
        ? styles.featureRowTrialPadding
        : undefined;
    };

    const bgColor = isTrialSignUpPage
      ? "primary--white-bg"
      : "backgroundYellow";

    let benefits = benefitsList?.map((item, index) => (
      <div
        key={index}
        className={`${styles.featureRow} ${hasPadding(
          index
        )} pad-bottom--16 ${textFont}`}
      >
        <BlackCheckmark cls={styles.icon} />
        {item.benefit}
        {isTrialSignUpPage && (
          <Tooltip
            className={styles.tooltip}
            contentClassName={styles.tooltipContent}
            content={item.tooltip}
          >
            <InfoIcon dark={true} className={styles.trialSignUpTooltipIcon} />
          </Tooltip>
        )}
      </div>
    ));

    const BenefitsTitle = () => {
      return (
        isTrialSignUpPage && (
          <div className={styles.benefitsTitle}>{benefitsListTitle}</div>
        )
      );
    };

    const signUpFormTitle = isTrialSignUpPage
      ? "Begin your free, 30-day trial."
      : null;

    const featuresPageHeroSubcopyClasses = isTrialSignUpPage
      ? ""
      : "hide-for-small";

    return (
      <section
        data-testid="section:FormContainer"
        className={`pad-top--large-sans-header pad-bottom--large pos--relative ${bgColor}`}
      >
        <div className="full-width">
          <div className={styles.gridFeatures}>
            <div className={styles.heroTopAlign}>
              <h1 className={`pad-bottom--8 ${textFont}`}>
                {featuresPageHeroTitle}
              </h1>
              <div className="hide-for-medium-only">
                <HorizontalRule variant="hr--8" />
                <p
                  className={`pad-top--8 ${featuresPageHeroSubcopyClasses} ${textFont}`}
                  dangerouslySetInnerHTML={{ __html: featuresPageHeroSubcopy }}
                />
              </div>
              <HorizontalRule variant="hr--24" />
              <div>
                <BenefitsTitle />
                {benefits}
                {!isTrialSignUpPage && (
                  <div className={`pad-top--8 ${textFont}`}>{footerCopy}</div>
                )}
              </div>
            </div>
            <SignUpForm
              specialtiesId={specialtiesId}
              page={isTrialSignUpPage ? "trialSignUp" : "monarchTrialSignUp"}
              signUpTitle={signUpFormTitle}
            />
          </div>
        </div>

        <CustomerSatisfactionBanner />
      </section>
    );
  };

export default MonarchTrialSignUpFormContainer;
